import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { appStore, googlePlay } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mt="20px" mb="10px" column center>
          <CFView h3 futura bold color="#FF001F" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column center white bold>
            <CFView h5 mb="15px" textCenter maxWidth="75vw">
              Sushi Modo is now taking online orders. Order through our website
              or download our app in your app store!
            </CFView>
            <CFView row center>
              <CFView mr="10px">
                <CFLink href="https://apps.apple.com/us/app/sushi-modo/id1537279669">
                  <CFImage h="40px" src={appStore} alt="Sushi Modo App Store" />
                </CFLink>
              </CFView>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.sushimodo">
                <CFImage
                  h="40px"
                  src={googlePlay}
                  alt="Sushi Modo Google Play"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mr="25px" column justifyStart>
          <CFView h3 futura bold color="#FF001F" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column justifyStart white bold>
            <CFView maxWidth="260px" fontSize="14px" mb="15px">
              Sushi Modo is now taking online orders. Order through our website
              or download our app in your app store!
            </CFView>
            <CFLink href="https://apps.apple.com/us/app/sushi-modo/id1537279669">
              <CFImage
                h="3.5rem"
                src={appStore}
                alt="Sushi Modo App Store"
                hover
              />
            </CFLink>
            <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.sushimodo">
              <CFImage
                h="3.5rem"
                mt="5px"
                src={googlePlay}
                alt="Sushi Modo Google Play"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
