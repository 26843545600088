import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  LocationSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
        >
          <CFView textCenter column center w="100%">
            <CFView column center>
              <CFImage src={mobileHeroText} w="100%" alt="Promotions" />
              <CFView
                mt="10px"
                br="45px"
                bc="white"
                bw="4.5px"
                ph="10px"
                pv="5px"
                center
                pulsate
              >
                <CFView white mh="10px" mb="2px" xBold style={{ fontSize: 24 }}>
                  BURNABY LOCATION
                </CFView>
              </CFView>
            </CFView>
            {/* <CFView mv="15px">
              <LocationSelect />
            </CFView> */}
            <CFView mv="20px">
              <OrderPickupButton />
            </CFView>
            <CFView>
              <DownloadAppButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          relative
          column
          center
        >
          <CFView absolute top={0} left={0}>
            <Header />
          </CFView>
          <CFView w="100%" column center>
            <CFView column center>
              <CFImage src={heroText} w="55%" alt="Promotions" />
              <CFView
                mt="10px"
                br="45px"
                bc="white"
                bw="4.5px"
                ph="10px"
                pv="5px"
                center
                pulsate
              >
                <CFView white mh="10px" mb="2px" xBold style={{ fontSize: 28 }}>
                  BURNABY LOCATION
                </CFView>
              </CFView>
            </CFView>
            <CFView mt="20px">
              <OrderPickupButton />
            </CFView>
            <CFView mv="10px">
              <DownloadAppButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
