import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/NmljFgO1p1b0DnWwM4Lw/locations/Ab8324iqgy4BQgWK2Ufi'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%" h="70px">
          <CFView row center>
            <CFImage h="55px" src={logo} alt="Sushi Modo Logo" />
            <CFImage
              h="30px"
              ml="20px"
              src={logoTitle}
              alt="Sushi Modo Logo Title"
            />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" h="70px" zIndex={98}>
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row center mt="15px">
              <CFImage
                h="60px"
                ml="15px"
                src={logo}
                alt="Sushi Modo Logo"
                zIndex={98}
              />
              <CFImage
                h="30px"
                mh="15px"
                src={logoTitle}
                alt="Sushi Modo Logo Title"
              />
              <CFView
                h="45px"
                w="100%"
                br="25px"
                bc="white"
                bw="3px"
                pv="5px"
                ph="5px"
                center
              >
                <CFView white mh="10px" mb="2px" xBold>
                  BURNABY
                </CFView>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
