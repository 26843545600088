import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          {/* <CFImage
            src={mobileAbout}
            w="100%"
            pv="5px"
            alt="Sushi Modo About"
          /> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto" maxWidth="1250px">
          {/* <CFImage src={about} w="100%" alt="Promotions" /> */}
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
